import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserData } from "../hooks/useUserData";
import {
  DWSSelect,
  DWSButton,
  Loading,
} from '@dws/dws-styleguide';
import { ProfilePageHeader } from "./ProfilePageHeader";

type ProfilePageProps = Record<string, unknown> & { children?: React.ReactNode };

export function ProfilePage(_: ProfilePageProps) {
  const { t } = useTranslation();
  const { getUserData, saveUserLanguage } = useUserData();
  const { data: userData, isLoading: isLoadingGetUserData, isFetching, isError } = getUserData();
  const {
    mutateAsync: updateUserLanguage, 
    isLoading: isLoadingUpdateUserLanguage,
  } = saveUserLanguage();

  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);

  useEffect(() => {
    if (userData) setSelectedLanguage(userData?.userConfiguration?.language);
  }, [userData]);

  const languageOptions = useMemo(() => [
    { label: t('profile.english'), value: 'en' },
    { label: t('profile.french'), value: 'fr' },
    { label: t('profile.dutch'), value: 'nl' },
    { label: t('profile.portuguese'), value: 'pt' },
    { label: t('profile.spanish'), value: 'es' },
  ], [t, userData]);

  if (isLoadingGetUserData) return <Loading />;
  if (isError) return <div>{t('general.error')}</div>;

  const handleSaveLanguage = () => {
    if (!userData || !selectedLanguage) return;

    updateUserLanguage(selectedLanguage);
  }

  return (
    <div className="profile-page-container">
      <ProfilePageHeader />
      <div className="profile-page-content">
        <DWSSelect 
          className='select-default'
          placeholder={t('profile.select_language')}
          options={languageOptions}
          value={selectedLanguage}
          onChange={setSelectedLanguage}
          label="label"
        />
        <DWSButton
          data-test-id='save-language-button'
          className='btn btn-purple'
          onClick={handleSaveLanguage}
          disabled={userData?.userConfiguration?.language === selectedLanguage}
          loading={isLoadingUpdateUserLanguage || isLoadingGetUserData || isFetching}
        >
          {t('general.save')}
        </DWSButton>

      </div>

    </div>
  );
}
