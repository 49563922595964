import axios, { AxiosResponse } from 'axios';
import { getHeaders, config } from '@dws/dws-auth';
import { UserConfiguration } from '../types/UserProfile';

export function updateUserLanguage(language: string): Promise<AxiosResponse<UserConfiguration>> {
    const url = `${config?.ENV_URL_BCK}/v1/UserConfiguration/`;
    const axiosConfig = getHeaders();
    const response = axios.put<UserConfiguration>(url, { language }, axiosConfig)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new Error(error);
      });
    return response;
};
