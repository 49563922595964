import { useQuery, useMutation, useQueryClient, QueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { fetchUserData } from '../helpers/fetchUserData';
import { updateUserLanguage } from '../helpers/updateUserLanguage';
import { UserProfile } from '../types/UserProfile';

export const useUserData = () => {

  const { t, i18n } = useTranslation();
  
  function handleGetUserDataSuccess(data: UserProfile) {
    return i18n.changeLanguage(data?.userConfiguration?.language || 'en');
  }

  function handleGetUserDataError() {
    return M.toast({
      html: t("profile.error_user_data"),
      classes: "toast-warning",
      displayLength: 4000,
    });
  };

  async function handleSaveLanguageSuccess(response: any, client: QueryClient) {
    client.invalidateQueries(['userData']);
    await i18n.changeLanguage(response?.data?.language);

    M.toast({
      html: t("profile.success_updating_user_language"),
      classes: "toast-success",
      displayLength: 4000,
    });
  };
  
  function handleSaveLanguageError() {
    M.toast({
      html: t("profile.error_updating_user_language"),
      classes: "toast-warning",
      displayLength: 4000,
    });
  };
  
  function getUserData() {
    return useQuery(['userData'], fetchUserData, {
      onSuccess: handleGetUserDataSuccess,
      onError: handleGetUserDataError,
    });
  };

  function saveUserLanguage() {
    const client = useQueryClient();
    return useMutation((language: string) => {
      return updateUserLanguage(language);
    }, {
      onSuccess: (response) => handleSaveLanguageSuccess(response, client),
      onError: handleSaveLanguageError,
    });
  }
  
  return { getUserData, saveUserLanguage };
};
