import axios from 'axios';
import {
  getHeaders,
  config
} from '@dws/dws-auth';

/**
 * @deprecated The method should not be used, use the API directly
 */
const useUserApi = async () => {
  const url = config?.ENV_URL_BCK + '/v1/User/';
  const axiosConfig = getHeaders();
  
  try {
    return await axios.get(url, axiosConfig).then((response) => { return response })
  } catch (e) {
    console.log('useUserApi error', e);
  }
}

export {
  useUserApi
};
