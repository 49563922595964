import React from "react";
import { useTranslation } from "react-i18next";

export function ProfilePageHeader() {
    const { t } = useTranslation();
    return (
    <div className="profile-page-header">
        <h1>{t("profile.profile_page_title") as string}</h1>
        <p>{t("profile.profile_page_subtitle") as string}</p>
    </div>
    );
};