import { ProfilePage } from "./components/ProfilePage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

type RootProps = Record<string, unknown> & { children?: React.ReactNode };

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  },
});
export default function Root(props: RootProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <ProfilePage {...props} />
    </QueryClientProvider>
  );
}
