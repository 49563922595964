import React, { ReactElement, ComponentType } from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";
import './i18n';
import "./datadog";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: () => <Root />,
  errorBoundary(
    err: Error,
    errInfo: React.ErrorInfo,
    props: ComponentType
  ): ReactElement<unknown> {
    // Customize the root error boundary for your microfrontend here.
    return (
      <div>
        <h1>Error Occurred</h1>
        <p>{err.message}</p>
      </div>
    );
  },
});

export const { bootstrap, mount, unmount } = lifecycles;

/**
 * @deprecated The method should not be used, use the API directly
 */
export { useUserApi } from './hooks/useUserApi';

/**
 * @deprecated The method should not be used, use the i18next directly
 */
export { useTranslation } from 'react-i18next';
