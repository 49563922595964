import axios from 'axios';
import { getHeaders, config } from '@dws/dws-auth';
import { UserProfile } from '../types/UserProfile';

export async function fetchUserData(): Promise<UserProfile> {
    try {
        const url = `${config.ENV_URL_BCK}/v1/User/`;
        const axiosConfig = await getHeaders();
        const response = await axios.get<UserProfile>(url, axiosConfig)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error);
            });
        return response.data;
    } catch (error) {
        throw new Error((error as Error).message);
    };
};
